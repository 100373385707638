import { countries } from 'countries-list'
import { format } from 'date-fns'

import type { TOption, TOptionResponse, TProcessTXTResult } from '@assets/types/globals'

import { IFilteredGraphics } from '@/store/metrics/types'

export function setFilterData(data: any) {
  for (const key in data) {
    if (!data[key] || !data[key].length) {
      delete data[key]
      continue
    }

    if (Array.isArray(data[key])) {
      data[key] = data[key].map((item: TOption) => item.value).join(',')
    }
  }

  return data
}

/**
 * Перетворює об'єкт URLSearchParams у звичайний об'єкт JavaScript.
 *
 * Функція приймає об'єкт URLSearchParams, який зазвичай використовується для роботи з параметрами запиту URL,
 * і перетворює його на звичайний об'єкт JavaScript, де ключі та значення параметрів запиту стають ключами та значеннями об'єкта.
 * Це може бути корисно для легшого доступу та маніпуляції параметрами запиту в коді JavaScript.
 *
 * @param {URLSearchParams} searchParams - Об'єкт URLSearchParams, який містить параметри запиту URL.
 * @returns {Record<string, string>} Об'єкт JavaScript, де ключі та значення відповідають параметрам запиту URL.
 */
export function transformSearch(searchParams: URLSearchParams): Record<string, string> {
  // Ініціалізуємо порожній об'єкт для зберігання результатів.
  const paramsObj: Record<string, string> = {}

  // Ітеруємо через кожну пару ключ-значення в об'єкті URLSearchParams.
  for (let [key, value] of searchParams) {
    if (key === 'link_params') {
      value.split(',').forEach((v: string, i: number) => {
        paramsObj[`link_params[${i}]`] = decodeURIComponent(v)
      })
      continue
    }
    if (key === 'rp_text') {
      paramsObj[key] = decodeURIComponent(value)

      continue
    }

    if (['celebrity_id', 'country_id', 'crm_id', 'funnel_id'].includes(key)) {
      value.split(',').forEach((v: string, i: number) => {
        paramsObj[`${key}[${i}]`] = v
      })

      continue
    }

    paramsObj[key] = value
  }

  // Повертаємо об'єкт JavaScript з параметрами запиту.
  return paramsObj
}

/**
 * Transforms an object into a new object with specific key-value pair formatting.
 *
 * This function takes an object where the keys are strings and the values are strings.
 * It processes the object to transform certain keys ('link_params' and 'celebrity_id')
 * into a specific format where each value is split by commas and indexed.
 * The resulting object contains these transformed key-value pairs.
 *
 * @param {Record<string, string>} obj - The input object with key-value pairs to be transformed.
 * @returns {Record<string, string>} The transformed object with formatted key-value pairs.
 */
export function transformObjToParams(obj: Record<string, string>): Record<string, string> {
  const paramsObj: Record<string, string> = {}

  for (let [key, value] of Object.entries(obj)) {
    if (!value) {
      continue
    }

    if (key === 'link_params') {
      value.split(',').forEach((v: string, i: number) => {
        if (!!v?.length) {
          paramsObj[`link_params[${i}]`] = v
        }
      })

      continue
    }

    if (key === 'celebrity_id') {
      value.split(',').forEach((v: string, i: number) => {
        paramsObj[`celebrity_id[${i}]`] = v
      })

      continue
    }

    paramsObj[key] = value
  }

  return paramsObj
}

export const setOptions = (list?: Array<{ id: number; name: string }>): TOption[] => list?.map((item) => ({ label: item.name, value: item.id })) || []

/**
 * Визначає наявність автологін посилання серед наданих URL та повертає інформацію про автологін.
 *
 * Функція перевіряє масив рядків (URL), щоб знайти хоча б одне посилання, яке відповідає певним шаблонам,
 * асоційованим з автоматичним входом користувача (автологін). Це дозволяє автоматизувати процес входу,
 * знаходячи спеціальні посилання, які містять токени або ідентифікатори сесії.
 *
 * @param {string[]} matches - Масив рядків (URL), які потрібно перевірити на наявність автологін посилань.
 * @returns {object} Об'єкт, що містить поля `autoLogin` (булеве значення, яке вказує на наявність автологін посилання)
 *                   та `autoLoginLink` (рядок з URL автологін посилання або порожній рядок, якщо таке посилання не знайдено).
 */
export const getAutologinLink = (matches: string[]) => {
  // Перевіряємо, чи масив не порожній. Якщо порожній, повертаємо об'єкт з `autoLogin` як false та порожнім `autoLoginLink`.
  if (matches.length === 0) {
    return { autoLogin: false, autoLoginLink: '' }
  }

  // Масив шаблонів URL, які можуть вказувати на автологін.
  const autoLoginPatterns = [
    'auto-login',
    '/autologin',
    '/authorization',
    '/?token',
    '/deposit',
    '/auth',
    '?signup',
    '/checkout',
    '/log',
    '/alogin',
    '/leads-workflow/go',
    '/TokenLogin',
    '/fbredirect',
    '/?tknlnd',
    '/?login_token',
    'api.brillian-tracks.com/thank_you/',
    '/client/verification',
    '/?crmToken',
  ]

  // Знаходимо перше посилання, яке відповідає одному з шаблонів автологіну, але не містить 'lander'.
  const match = matches.find((match) => autoLoginPatterns.some((pattern) => match.includes(pattern)) && !match.includes('lander'))

  // Повертаємо об'єкт з результатом: якщо знайдено відповідне посилання, `autoLogin` буде true та вказано `autoLoginLink`.
  return {
    autoLogin: !!match,
    autoLoginLink: match ?? '',
  }
}

/**
 * Розділяє вхідний рядок, вставляючи перенос рядка перед кожним URL, що починається з "http".
 *
 * Функція шукає всі посилання (URL), які починаються з "http", у вхідному рядку. Для кожного знайденого URL
 * перед ним вставляється символ переносу рядка, щоб відокремити посилання від попереднього тексту.
 * Це дозволяє легше візуально ідентифікувати та обробляти посилання у тексті.
 *
 * @param {string} inputString - Вхідний рядок, який може містити текст та одне або кілька URL.
 * @returns {string} Рядок, де кожне посилання розміщене на новому рядку.
 */
export function splitLinks(inputString: string): string {
  // Регулярний вираз для пошуку посилань. Він шукає пробільний символ або початок рядка (\s|^),
  // за яким слідує "http" та продовжується непробільними символами (\S+), що відповідає URL.
  const regex = /(\s|^)(http\S+)/g

  // Замінює кожен знайдений URL, вставляючи перед ним перенос рядка.
  // Параметри функції заміни:
  // _ - повний збіг, який не використовується;
  // p1 - збіг з пробільним символом або початком рядка;
  // p2 - збіг з URL.
  return inputString.replace(regex, (_, p1, p2) => `${p1}\n${p2}`)
}

/**
 * Обробляє текстовий файл, завантажений користувачем, і визначає потрібні дані.
 *
 * Функція читає вміст файлу, шукає всі URL-адреси, визначає наявність автоматичного входу,
 * визначає основний домен CRM і визначає шлях перенаправлення.
 *
 * @param {File} file - Текстовий файл, завантажений користувачем.
 * @returns {Promise<TProcessTXTResult>} Проміс, що вирішується об'єктом з результатами обробки.
 * @throws {Error} Помилка, якщо вміст файлу не є рядком або якщо посилань не знайдено.
 */
export function processTXTFileOnUpload(file: File): Promise<TProcessTXTResult> {
  return new Promise((resolve, reject) => {
    // Оголошуємо об'єкт для зберігання результатів обробки.
    const result: TProcessTXTResult = {
      redirectPath: '',
      rpLinks: [],
      autoLogin: false,
      autoLoginLink: '',
    }

    // Створюємо об'єкт FileReader для читання вмісту файлу.
    const reader = new FileReader()

    // Обробляємо подію onload, яка викликається після завершення читання файлу.
    reader.onload = (event) => {
      // Отримуємо вміст файлу з властивості event.target.result.
      const fileContent = event.target?.result

      // Перевіряємо, чи вміст файлу є рядком.
      if (typeof fileContent !== 'string') {
        // Якщо вміст не є рядком, викидаємо помилку.
        reject(new Error('Вміст файлу не є рядком'))
        return
      }

      // Створюємо регулярний вираз для пошуку URL-адрес.
      const urlRegex = /https?:\/\/\S+/g
      // Знаходимо всі URL-адреси у вмісті файлу.
      const matches = fileContent.match(urlRegex)

      result.rpLinks = matches ?? []

      // Перевіряємо, чи знайдено хоча б один URL-адрес.
      if (!matches) {
        // Якщо URL-адреси не знайдено, викидаємо помилку.
        reject(new Error('Посилань не знайдено'))
        return
      }

      // Визначаємо наявність автоматичного входу та отримуємо URL-адресу автоматичного входу.
      const autoLoginState = getAutologinLink(matches)
      result.autoLogin = autoLoginState.autoLogin
      result.autoLoginLink = autoLoginState.autoLoginLink

      // Масив шаблонів для пошуку основного домену CRM.
      // const partsArr = ['/u/d/', '3heads-media.tech', '/sdk/v1/leads-workflow/', '/app-api/', '/api/autologin?token']

      // const crmTypes = new Map([
      //   ['/sdk/v1/leads-workflow/', 'IREV'],
      //   ['/app-api/', 'Elnopy'],
      //   ['/u/d/', 'Trackbox'],
      // ])

      // const links: string[] = []
      //
      // matches.forEach((match) => {
      //   if (partsArr.some((part) => match.includes(part))) {
      //     const url = new URL(match)
      //     const domain = url.hostname
      //     result.crm.push({
      //       name: domain,
      //       type: crmTypes.get('part') ?? '',
      //     })
      //   }
      // })
      //
      // result.crm = links[0] || ''

      // Знаходимо перший URL-адресу, який містить один з шаблонів, та отримуємо основний домен CRM.
      // const foundUrl = matches.find((match) => partsArr.some((part) => match.includes(part)))
      // result.crm = foundUrl ? new URL(foundUrl).hostname : ''

      // Розділяємо вміст файлу, вставляючи перенос рядка перед кожним URL-адресом.
      result.redirectPath = splitLinks(fileContent)

      // Вирішуємо проміс з результатами обробки.
      resolve(result)
    }

    // Обробляємо подію onerror, яка викликається, якщо виникає помилка читання файлу.
    reader.onerror = (error) => {
      // Викидаємо помилку з описом причини помилки.
      reject(new Error(`Помилка читання файлу: ${error}`))
    }

    // Запускаємо читання файлу.
    reader.readAsText(file)
  })
}

/**
 * Retrieves the country code for a given country name.
 *
 * This function iterates through the list of countries and returns the code
 * corresponding to the provided country name. If the country name is not found,
 * it returns an empty string.
 *
 * @param {string} countryName - The name of the country for which the code is to be retrieved.
 * @returns {string | undefined} The country code if found, otherwise an empty string.
 */
export function getCountryCode(countryName: string): string | undefined {
  for (const [code, country] of Object.entries(countries)) {
    if (country.name === countryName) {
      return code
    }
  }
  return ''
}

/**
 * Constructs a title string based on provided arguments.
 *
 * This function takes an object of arguments, extracts the country name,
 * converts it to its corresponding country code, and then concatenates
 * the country code with the other argument values to form a title string.
 *
 * @param {Record<string, string>} args - An object containing key-value pairs where the key is a string and the value is a string.
 * @returns {string} The constructed title string. If the resulting title is empty, an empty string is returned.
 */
export function assemblyTitle(args: Record<string, string>): string {
  const { country, ...otherArgs } = args

  const title = [getCountryCode(country), ...Object.values(otherArgs)].join(' ')

  return !title.trim().length ? '' : title
}

/**
 * Transforms an TOptionResponse object into an TOption object.
 *
 * This function takes an TOptionResponse object, which contains the properties `name` and `id`,
 * and transforms it into an TOption object with `label` and `value` properties.
 *
 * @param {TOptionResponse} option - The option object to be transformed. It should have `name` and `id` properties.
 * @returns {TOption} The transformed option object with `label` and `value` properties.
 */
export function assemblyOptions(option: TOptionResponse): TOption {
  if (!option) return option

  return {
    label: option.name,
    value: option.id,
  }
}

export function isExternalURL(str: string): boolean {
  return /^(?:\w+:)?\/\/([^\s.]+\.\S{2}|localhost[:?\d]*)\S*$/.test(str)
}

export type TFormatForGraphics = {
  title: string
  data: Array<{
    name: string
    value: number
  }>
}

export function formatDateMetrics(data: Record<string, number>): Array<{ name: string; value: number }> {
  const sortedArray = Object.entries(data).sort((a, b) => a[0].localeCompare(b[0]))

  return sortedArray.map(([key, value]) => ({
    name: format(new Date(key), 'dd.MM'),
    value,
  }))
}

export function formatForGraphics({ data }: IFilteredGraphics<TOption>): TFormatForGraphics[] {
  return Object.entries(data)?.map(([key, value]) => ({
    title: key,
    data: Object.entries(value).map(([k, v]) => ({
      name: k,
      value: v,
    })),
  }))
}

export function countryNameByCode(code: string) {
  const label = code?.toUpperCase()

  if (label && label in countries) {
    return countries[label as keyof typeof countries].name
  }
  return 'unknown'
}

export function formatDuration(seconds: number): string {
  const days = Math.floor(seconds / (24 * 3600))
  const daysStr = days ? ` ${days} d. ` : ''

  seconds %= 24 * 3600
  const hours = Math.floor(seconds / 3600)
  const hoursStr = hours ? ` ${hours} h. ` : ''

  seconds %= 3600
  const minutes = Math.floor(seconds / 60)
  const minutesStr = minutes ? ` ${minutes} m. ` : ''

  seconds %= 60
  const secondsStr = seconds ? ` ${seconds} s.` : ''

  return `${daysStr}${hoursStr}${minutesStr}${secondsStr}`
}
