import React, { Suspense, lazy, useEffect } from 'react'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'

import { useAuthenticator } from '@aws-amplify/ui-react'

import { AccessDenied } from '@components/AccessDenied'
import { AuthLogging } from '@components/AuthLoggingComponent'
import { Layout } from '@components/Layout'

import { AppLoader } from '_ui/Loader'

const AuthForm = lazy(() => import('@components/AuthForm').then((module) => ({ default: module.AuthForm })))

const HomePage = lazy(() => import('@pages/HomePage').then((module) => ({ default: module.HomePage })))
const CreatePage = lazy(() => import('@pages/CreatePage').then((module) => ({ default: module.CreatePage })))
const AppendPage = lazy(() => import('@pages/AppendPage').then((module) => ({ default: module.AppendPage })))
const FavoritesPage = lazy(() => import('@pages/FavoritesPage').then((module) => ({ default: module.FavoritesPage })))
const MetricsPage = lazy(() => import('@pages/MetricsPage').then((module) => ({ default: module.MetricsPage })))
const DeletePage = lazy(() => import('@pages/DeletePage').then((module) => ({ default: module.DeletePage })))
const LogsPage = lazy(() => import('@pages/LogsPage').then((module) => ({ default: module.LogsPage })))

const pages = [
  {
    path: '/',
    index: true,
    component: (
      <HomePage
        key="home"
        isSites={false}
      />
    ),
  },
  {
    path: '/sites',
    index: true,
    component: (
      <HomePage
        key="lands"
        isSites
      />
    ),
  },
  { path: '/create', index: false, component: <CreatePage key={'create'} /> },
  {
    path: '/edit/:id',
    index: false,
    component: (
      <CreatePage
        key={'edit'}
        edit
      />
    ),
  },
  { path: '/append', index: false, component: <AppendPage /> },
  { path: '/metrics', index: false, component: <MetricsPage /> },
  { path: '/favorites', index: false, component: <FavoritesPage /> },
  { path: '/delete', index: false, component: <DeletePage /> },
  { path: '/logs', index: false, component: <LogsPage /> },
]

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: pages.map((page) => ({ element: <Suspense fallback={<AppLoader />}>{page.component}</Suspense>, ...page })),
  },
  {
    path: '*',
    element: <AccessDenied noSecure />,
  },
])

export default function App() {
  return (
    <>
      <AuthLogging />
      <AppContent />
    </>
  )
}

function AppContent() {
  const { route } = useAuthenticator((context) => [context.user, context.route, context.signOut])

  useEffect(() => {
    if (route === 'authenticated') {
      document.title = 'Di💎mond SPY'
    }
  }, [route])

  return route !== 'authenticated' ? <AuthForm /> : <RouterProvider router={router} />
}
